<template>
  <LiefengModal
      title="添加公布内容"
      :fullscreen="fullscreen"
      :value="valueType"
      @click="toggleFn"
  >
    <template v-slot:contentarea>
      <div class="container">
        <div style="width: 200px" class="left">
          <Input
              v-model.trim="treeVal"
              placeholder="请输入关键字进行过滤"
              style="width: 200px"
              @on-change="searchTree"
          />
          <Tree
              :data="treeData"
              @on-select-change="selectTree"
              style="width: 200px"
              :select-node="true"
          ></Tree>
        </div>
        <div class="main">
          <div class="search">
            <Input
                v-model="inputVal"
                style="width: 200px; margin-left: 10px"
                placeholder="请输入标题"
            ></Input>
            <Button
                type="primary"
                style="margin: 0 10px"
                icon="ios-search"
                @click="search"
            >查询
            </Button
            >
            <Button type="success" icon="ios-refresh" @click="reset"
            >重置
            </Button
            >
          </div>
          <LiefengTable
              ref="table"
              :talbeColumns="tableColumns"
              :curPage="page"
              :total="total"
              :tableData="tableData"
              :height="tableHeight"
              :loading="loading"
              :pagesizeOpts="[50,200,500,1000]"
              :pageSize="pageSize"
              @hadlePageSize="hadlePageSize"
              @tableSelect="tableSelect"
          ></LiefengTable>
        </div>
        <div class="button">
          <Button type="info" @click="toRight">>></Button>
          <Button type="warning" @click="delRight">
            <Icon type="ios-trash-outline" size="24"/>
          </Button>
          <!--          <Button type="error" @click="clearRight">清空</Button>-->
        </div>
        <div class="right">
          <div>
            <Button type="success" icon="ios-cloud-upload-outline" class="rightTitle" @click="aboutLinkSave">提交</Button>
          </div>
          <List size="small">
            <LiefengTableToggle
                ref="table"
                :talbeColumns="tableColumns1"
                :tableData="tableData1"
                :height="tableHeight"
                @tableSelect="tableSelect1"
            >
            </LiefengTableToggle>
          </List>
        </div>
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" @click="closeFn">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script>
//@route('/announce')
import LiefengModal from "@/components/LiefengModal";
import LiefengTableToggle from "./LiefengTableToggle"
import LiefengTable from "@/components/LiefengTable";

export default {
  props: {
    valueType: Boolean,
    fullscreen: {
      default: Boolean
    },
    closeFn: Function,
    toggleFn: Function
  },
  data() {
    return {
      labelObj: {},
      dataInfoId: {},
      selectList: {},
      labelListType: {},
      treeVal: "",
      treeData: [],
      fullTreeData: [],
      //   菜单树定时器
      timeOut: null,
      removeTableSelectArr: [], // need delete
      tableHeight: '',
      modalStatus: true,
      cancelModalTip: false,
      orgList: [],
      orgCode: "",
      orgCodeName: '',
      tableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 120,
          align: "center",
        },
        {
          title: "编码",
          key: "code",
          width: 140,
          align: "center",
        },
      ],
      tableColumns1: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 120,
          align: "center",

        },
        {
          title: "选择标签",
          key: 'labelName',
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            // console.log(this.tableData1[params.index].labelCodes, 'ljj params2')
            return h('Select', {
                  props: {
                    value: this.tableData1[params.index].labelCodes,
                    multiple: 'multiple'
                  },
                  on: {
                    'on-change': (event) => {
                      this.selectOptions(params.index, event)
                    }
                  },
                },
                this.labelListType.map(function (type) {
                  return h('Option', {
                    props: {value: type.labelCode}
                  }, type.labelName);
                })
            );
          },
        },
        {
          title: "编码",
          key: "code",
          width: 140,
          align: "center",
        },
      ],
      //表格1列表获取参数
      postData: {},
      inputVal: '',
      tableData: [],
      tableData1: [],
      loading: false,
      pageSize: 10,
      total: 0,
      page: 1,

      // 级联
      cascaderData: [],
      cascader: [],
      // 搜索
      name: "",
      // 标识选择市，区或街
      index: "",

      tableSelectArr: [],
      rightList: [],
      removeRightList: [],
      selectObj: {
        status: "1",
        nameOrMobile: "",
      },
    };
  },
  methods: {
    async toggleShuju() {
      //树形数据展示
      this.tableHeight = window.innerHeight - 200 + '';
      await this.$get("/datamsg/api/pc/menu/findInformationBusinessTree", {
        staffId: parent.vue.loginInfo.userinfo.id,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
      })
          .then((res) => {
            if (res.code == 200 && res.dataList) {
              this.$Message.destroy()
              this.treeData = this.getTreeData(res.dataList);
              this.fullTreeData = JSON.parse(JSON.stringify(this.treeData));
            } else {
              this.$Message.destroy()
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .catch((err) => {
            this.$Message.destroy()
            console.log(err);
            this.$Message.error({
              background: true,
              content: "数据加载失败，请重试",
            });
          });


      //获取标签
      await this.$get("/datamsg/api/pc/platform/label/selectPlatform", {  //fuck
        labelType: 1
      })
          .then((res) => {
            this.loading = false;
            if (res.code == 200 && res.dataList) {
              this.$Message.destroy()
              this.labelListType = res.dataList
              // console.log(this.labelListType, 'labelListType')
              this.labelListType.map(item => {
                this.labelObj[item.labelCode] = item.labelName
              })
              // console.log(this.labelObj, 'this.labelObj')
            } else {
              this.$Message.destroy()
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .then(() => {
            this.$get('/datamsg/api/pc/information/polymerization/queryPolymerizationLabel', {
              oemCode: parent.vue.oemInfo.oemCode
            }).then(res => {  //回显，获取提交后返回来的数据
              //   获取已存在的右边表格数据
              if (res.code == 200 && res.dataList) {
                this.$Message.destroy()
                this.tableData1 = res.dataList.map((item, index) => {
                  return {
                    infoCode: item.code,
                    infoId: item.infoId,
                    labelCodes: item.labelCodes,
                    title: item.title
                  }
                })
              } else {
                this.$Message.destroy()
                this.$Message.error({
                  background: true,
                  content: res.desc
                })
              }
            })
          }).catch((err) => {
            this.$Message.destroy()
            console.log(err);
            this.$Message.error({
              background: true,
              content: "获取数据失败。请重试",
            });
          });
    },


    //选择标签给到list
    selectOptions(index, event) { //fuck
      this.tableData1[index].labelCodes = event
    },
    //提交
    aboutLinkSave() {
      this.tableData1 = this.tableData1.map((item,index) => {
        return {
          infoId: item.infoId,
          labelCodes: item.labelCodes,
          infoCode: item.infoCode,
          title: item.title
        }
      })

      this.$post('/datamsg/api/pc/information/polymerization/addPolymerizationLabel', this.tableData1, {"Content-Type": "application/json"}).then(res => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        console.log(err);
        this.$Message.error({
          background: true,
          content: '提交数据失败！请联系管理员解决'
        })
      })
    },
    // 防抖搜索tree
    searchTree() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.treeData = [];
        this.getSearchTree(this.fullTreeData);
      }, 500);
    },
    getSearchTree(list) {
      list.map((item, index) => {
        if (item.title.indexOf(this.treeVal) != -1) {
          //先找第一层
          this.treeData.push(item);
        } else if (item.children) {
          //目前子数据没有与夫数据关联起来的标识，无法做到搜索子数据的时候，把父数据也返回
          this.getSearchTree(item.children);
        }
      });
    },
    // 选择tree
    selectTree(obj) {
      if (obj && obj[0] && !!obj[0].columnCode) {
        this.postData = {
          orgCode: obj[0].orgCode,
          oemCode: obj[0].oemCode,
          columnCode: obj[0].columnCode,
          status: obj[0].businessType == 3 ? "2" : "4",
          columnType: obj[0].columnType,
          functionType: obj[0].functionType,
          businessType: obj[0].businessType,
          operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        };
        this.inputVal = '';
        this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize,
        });
      }
    },
    //   递归修改treeData
    getTreeData(arr) {  //arr=树形数据
      let treeData = arr.map((item, index) => {
        let obj = {};
        if (item.subMenus && item.subMenus.length > 0) {
          obj = {
            expand: false,
            children: this.getTreeData(item.subMenus),
          };
          if (index == 0) {
            obj.expand = true;
          }
        }
        return {
          ...obj,
          ...item,
          title: item.name,
        };
      });
      return treeData;
    },
    getList(obj) {
      this.selectObj = {
        ...this.selectObj,
        nameOrMobile: "",
        communityCode: obj.communityCode,
        regionId: obj.regionId,
        regionType: obj.regionType,
        roadCode: obj.roadCode,
      };
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },

    save() {
      if (this.rightList.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择需要授权的机构"
        })
        return;
      }
      this.$post('/datamsg/api/pc/platformMenu/singleMenuGrantOrg', {
        oemCode: window.top.vue.oemInfo.oemCode,
        orgCodes: (() => {
          let arr = [];
          this.rightList.map(item => {
            arr.push(item.orgCode);
          })
          return arr;
        })(),
        platformMenuId: window.location.href.substring(window.location.href.indexOf('?') + 1).split('=')[1],
      }, {"Content-Type": "application/json"}).then(res => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          })
          window.parent.close();
        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    },
    // 表格选中
    tableSelect(data) {
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
    },
    tableSelect1(data) {
      this.removeTableSelectArr = JSON.parse(JSON.stringify(data));
    },
    // 右移
    toRight() {
      let arrId = [];
      this.tableSelectArr.map(item => {
        arrId.push(item.infoId);
      })
      let arrTable1 = [];
      this.tableData.map((item, index, arr) => {
        if (arrId.indexOf(item.infoId) == -1) {
          arrTable1.push(item);
        }
      })
      this.tableData = arrTable1;
      this.tableData1 = this.unique(this.tableData1.concat(this.tableSelectArr));
      // console.log(this.tableData1, '又移')
      this.tableSelectArr = [];
      this.tableData1 = this.tableData1.map((item) => {
        return {
          title: item.title,
          infoCode: item.infoCode,
          infoId: item.infoId,
          labelCodes: item.labelCodes === 0 ? [] : item.labelCodes,
        }
      })
      // console.log(this.tableData1, 'ljj 移动过去了')


    },
    // 删除
    delRight() {
      let arrId = [];
      // console.log(this.removeTableSelectArr, 'this.removeTableSelectArr')
      this.removeTableSelectArr.map(item => {
        arrId.push(item.infoId);
      })
      let arrTable2 = [];
      // console.log(this.tableData1, 'this.tableData1')
      this.tableData1.map((item, index, arr) => {
        if (arrId.indexOf(item.infoId) == -1) {
          arrTable2.push(item);
          // console.log(arrTable2, 'arrTable2')
        }
      })
      this.tableData1 = arrTable2;
      // console.log(this.tableData1, '删除')
      this.removeTableSelectArr = [];
    },
    // 清空
    clearRight() {
      this.tableData1 = []
    },

    unique(arr) {
      let obj = {};
      arr = arr.reduce((pre, cur) => {
        obj[cur.infoId] ? '' : obj[cur.infoId] = pre.push(cur);
        return pre;
      }, []);
      return arr
    },
    //重置
    reset() {
      this.inputVal = "";
      this.search();
    },
    // 查询
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    // 获取表格数据
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/information/getDataList", {
        ...this.postData,
        page: obj.page,
        pageSize: obj.pageSize,
        title: this.inputVal,
        orgCode: parent.vue.loginInfo.userinfo.orgCode
      })
          .then((res) => {
            this.loading = false;
            if (res.code == 200 && res.dataList) {
              this.tableData = res.dataList;
              this.page = res.currentPage;
              this.pageSize = res.pageSize;
              this.total = res.maxCount;
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$Message.error({
              background: true,
              content: "获取数据失败。请重试",
            });
          });


    },
  },
  created() {


  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },

  components: {
    LiefengModal,
    LiefengTable,
    LiefengTableToggle,
  },
};
</script>

<style scoped lang='less'>
.container {
  display: flex;
  justify-content: space-around;
  height: calc(100vh - 123px);

  /deep/ .left {
    margin: 0 10px;
    width: 200px;
    height: 100%;

    /deep/ .ivu-select-dropdown {
      height: calc(100vh - 160px);
      max-height: calc(100vh - 160px);
    }
  }

  /deep/ .ivu-tree {
    padding: 0 6px;
    border-left: 1px solid #dcdee2;
    border-right: 1px solid #dcdee2;
    border-bottom: 1px solid #dcdee2;
    height: calc(100vh - 155px);
    overflow: auto;
  }

  .main {
    flex: 1;
    width: calc(33vw);

    .search {
      width: 100%;
      margin-bottom: 10px;
      align-items: center;
      background: #F8F8F9;
      text-align: right;
      padding-right: 30px;
    }

    & > div {
      width: 100%;
    }
  }

  .button {
    margin: 0 15px;
    width: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .ivu-btn {
      margin-bottom: 16px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
  }

  .right {
    //width: 640px;
    flex: 1;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    > div:first-child {
      width: 100%;
      margin-bottom: 10px;
      align-items: center;
      background: #F8F8F9;
      text-align: right;
      padding-right: 30px;

      .rightTitle {
      }
    }


    /deep/ .active {
      background: #19be6b;
      color: #fff;
    }
  }
}

/deep/ .ivu-modal-close {
  display: none;
}
</style>
